<template>
  <div>
    <article>
      <h3 class="center">{{ appName }} App涉及收集用户信息的第三方SDK或类似应用程序目录</h3>
      <section>
        <p>
          为保障{{ appName }}
          App的稳定运行以及实现包括但不限于第三方账号登录、消息推送、运营统计、第三方分享等的相关功能，我们会接入由第三方提供的第三方SDK或类似应用程序以实现前述目的；在通过第三方SDK或类似应用程序为您提供相应服务过程中，我们接入的第三方SDK或类似应用程序可能涉及收集您的个人信息，具体接入的第三方SDK或类似应用程序实现的业务功能、提供方、可能收集的信息等情况，详见下表
        </p>
        <p>
          <b
            ><u>
              注意：为了持续向您提供更多优质服务、持续优化我们提供服务的稳定性、服务质量或者升级相关功能，我们可能会调整我们接入的第三方SDK或类似应用程序并更新接入此目录。我们会严格遵守相关法律法规与监管要求，在接入SDK前评估第三方SDK或类似应用程序收集个人信息的合法性、正当性、必要性，以保护个人信息安全。</u
            ></b
          >
        </p>
      </section>
      <section>
        <div class="table">
          <div class="t-head">
            <ul>
              <li v-for="(item, index) in option.column" :key="index">{{ item.label }}</li>
            </ul>
          </div>
          <div class="t-body" v-for="(item, index) in tableData" :key="index">
            <ul>
              <li v-for="(context, i) in option.column" :key="i">
                <span v-if="context.prop !== 'Links'">{{ item[context.prop] }}</span>
                <span v-else class="align-start"
                  ><a :href="item[context.prop]" v-if="typeof item[context.prop] === 'string'">{{
                    item[context.prop]
                  }}</a>
                  <a :href="link" v-for="link in item[context.prop]" v-else>{{ link }} <br /></a>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
  export default {
    name: 'UserReleaseCommitment',
    components: {},
    props: {},
    data() {
      return {
        tableData: [
          {
            ProductName: 'Umeng（友盟）SDK+',
            CompanyName: '友盟同欣（北京）科技有限公司、北京锐讯灵通科技有限公司',
            Uses: '数据统计分析',
            Type: '设备信息-IMEI、设备信息-MAC、Android ID、OpenUDID、GUID、IP、DEVICEID',
            Links: 'https://www.umeng.com/page/policy',
          },
          {
            ProductName: '科大讯飞 SDK',
            CompanyName: '科大讯飞股份有限公司',
            Uses: '语音实时识别',
            Type: '设备号（IMEI号）、设备码（IMSI码）、IP地址、Android ID（安卓唯一设备ID以提供能力授权服务）、中国移动UAID（仅限安卓设备）、OAID（仅限安卓设备）、UUID（仅限iOS设备）、mac地址（仅限Linux设备）',
            Links: 'https://www.xfyun.cn/doc/asr/voicedictation/online_iat_sdkprivacy.html',
          },
        ],
        option: {
          column: [
            {
              label: '产品名称',
              prop: 'ProductName',
            },
            {
              label: '企业名称',
              prop: 'CompanyName',
            },
            {
              label: '用途/使用场景',
              prop: 'Uses',
            },
            {
              label: '收集的信息类型',
              prop: 'Type',
            },
            {
              label: '第三方个人信息保护政策链接或官网链接',
              prop: 'Links',
            },
          ],
        },
        appName: '',
        appNameMap: new Map([
          ['kuaiyin', { appName: '快音', moreOpts: false }],
          ['kuaiyintingge', { appName: '快音听歌版', moreOpts: false }],
          ['kuaiyinjisu', { appName: '快音极速版', moreOpts: false }],
          ['kuaiyinyue', { appName: '快音悦', moreOpts: true }],
          ['kuaiyinfm', { appName: '快音FM', moreOpts: true }],
          ['less', { appName: 'Less', moreOpts: false }],
        ]),
      };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
      let app_name = 'less';
      this.appName = this.appNameMap.get(app_name).appName;
      document.title = `${this.appName} App涉及收集用户信息的第三方SDK或类似应用程序目录`;
    },
    methods: {},
  };
</script>

<style scoped>
  body {
    font: 14px/150% 'Helvetica Neue', Tahoma, Arial, PingFangSC-Regular, 'Hiragino Sans GB', 'Microsoft Yahei',
      sans-serif;
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: #fff;
    /*padding-left: constant(safe-area-inset-left);*/
    /*padding-left: env(safe-area-inset-left);*/
    /*padding-right: constant(safe-area-inset-right);*/
    /*padding-right: env(safe-area-inset-right);*/
    /*padding-bottom: constant(safe-area-inset-bottom);*/
    /*padding-bottom: env(safe-area-inset-bottom);*/
  }

  article {
    margin: 0 4%;
  }
  h3 {
    margin: 15px 0;
    padding-top: 0;
    padding-bottom: 0;
    color: #3c3c3c;
    font-size: 15px;
  }
  h5 {
    margin: 15px 0;
    font-size: 14px;
  }
  p {
    margin: 12px 0;
    font-size: 13px;
    text-indent: 2em;
  }
  .center {
    text-align: center;
  }
  ul > li {
    margin: 5px 0;
    font-size: 13px;
    list-style: none;
  }
  ul > li > span {
    display: inline-block;
    margin: 4px 0;
  }
  .align-start {
    text-align: start;
  }
  .table {
    margin: 15px 0;
    border: 1px solid #aaa;
    width: 100%;
    border-bottom: 0;
  }
  .t-head {
    background: #c6c6c6 no-repeat scroll 0 0;
  }
  .t-head ul {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .t-head ul li {
    width: 20%;
    text-align: center;
  }
  .t-body {
    background-color: #fff;
  }
  .t-body ul {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #aaaaaa;
  }
  .t-body ul li {
    position: relative;
    width: 20%;
    padding: 4px;
    text-align: center;
    word-break: break-all;
  }
  a {
    color: #007aff;
  }
</style>
